import NotFoundIcon from "@material-ui/icons/SentimentDissatisfied"
import Link from "next/link"
import Head from "next/head"

const NotFound = () => {
    return <>
        <Head>
            <title>404 | Not Found</title>
            <link rel="icon" type="image/png" href="/favicon.png" sizes="32x32" />
            <link rel="icon" type="image/png" href="/favicon.png" sizes="16x16" />
        </Head>
        <div className="flex items-center justify-center h-screen w-screen">
            <div className="text-center">
                <div className="text-yellow-700">
                    <NotFoundIcon className="text-6xl"/>
                </div>
                <div className="text-6xl">
                    404
                </div>
                <div className="text-xl text-gray-400">
                    Took the wrong turn?
                </div>
                <div className="mt-4">
                    <Link href="/">
                        <button
                            className="bg-blue-600 hover:bg-green-600 p-2 shadow rounded-sm"
                        >
                            Back to Home
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    </>
}

export default NotFound